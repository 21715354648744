import React from "react";
import { useParams } from "react-router-dom";
import { usePost } from "utils/apis";

function Comment({ name, content, created_at, subcomments }) {
  return (
    <div>
      <div>
        {name || "익명"} - <span>{created_at}</span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <div>
        <ul>
          {subcomments.map((comment) => (
            <Comment {...comment} />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default function Post() {
  const { id } = useParams();
  const [result, error] = usePost(id);

  if (error) return <div>Error occurred while loading post</div>;
  if (!result) return <div>Post is loading...</div>;

  let {
    post: { title, content },
    comments,
  } = result;

  // This is absolutly tricky.
  // Must be fixed
  content = content.replace(/src="files/g, 'src="/api/v1/files');

  return (
    <div>
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: content }}></p>
      <hr></hr>
      <div>
        {comments.map((comment) => (
          <Comment {...comment} />
        ))}
      </div>
    </div>
  );
}
