import React from "react";
import { useHistory } from "../../utils/apis";
import "./History.scss";

export default function History() {
  const [data, error] = useHistory();

  if (error) return <div>Error occurred while loading history</div>;
  if (!data) return <div>Loading...</div>;

  return (
    <div className="history">
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
}
