import React from "react";
import { useMenus } from "./utils/apis";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.scss";
import Admin from "./Admin";
import History from "modules/history/History";
import Board from "modules/board/Board";
import Post from "modules/post/Post";
import EditPost from "EditPost";
import { Login } from "./Login";

function SubMenu({ name, url }) {
  return (
    <div className="sub-menu">
      <Link to={url}>{name}</Link>
    </div>
  );
}

function MainMenu({ name, url, submenus }) {
  return (
    <div className="main-menu">
      <div></div>
      <Link to={url}>{name}</Link>
      <div>
        <ul className="menu-list">
          {submenus.map((submenu) => (
            <li key={submenu.url}>
              <SubMenu {...submenu} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function Nav() {
  const [menus] = useMenus();
  if (!menus) return null;
  return (
    <nav className="nav">
      <h1>ZETIN</h1>
      {menus.map((menu) => (
        <MainMenu key={menu.url} {...menu} />
      ))}
      <Login />
    </nav>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <hr />
        <div>
          <Routes>
            <Route path="/edit" element={<EditPost />} />
            <Route path="/pages/history" element={<History />} />
            <Route path="/posts/:id" element={<Post />} />
            <Route path="/boards/:id" element={<Board />} />
            <Route path="/edit/:id" element={<EditPost />} />

            {/* Admin */}
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
