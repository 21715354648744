import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addPost, updatePost, useBoard, usePost } from "utils/apis";
import useQuery from "utils/useQuery";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";

export default function EditPost() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [boardInfo, boardError] = useBoard("");
  const [postInfo, postError] = usePost(id);

  const [title, setTitle] = useState("");
  const [board, setBoard] = useQuery("board");
  const [category, setCategory] = useState("");
  const [initialContent, setInitialContent] = useState(null);

  const ref = useRef();

  const boards = boardInfo?.boards || [];
  const categories =
    boardInfo?.categories.filter((x) => `${x.board_pk}` === `${board}`) || [];
  const version = postInfo?.post.version;

  useEffect(() => {
    if (postError || boardError) {
      setInitialContent("");
      return;
    }
    if (!postInfo || !postInfo.post) return;

    let { content, board_pk, title, category_pk } = postInfo.post;
    content = content
      .replace(/"http:/g, '"https:')
      .replace(/<img[^<>]*\/>/, (substr) => {
        return `<figure>${substr}</figure>`;
      });

    setTitle(title);
    setBoard(board_pk);
    setCategory(category_pk);
    setInitialContent(content);
  }, [postError, boardError, postInfo, setBoard]);

  async function handlePost() {
    const [result] = await addPost({
      board_pk: board,
      category_pk: category,
      title,
      content: ref.current.getInstance().getHTML(),
    });
    if (result) {
      navigate(`/posts/${result}`);
    } else {
      alert("포스트를 등록하지 못했습니다.");
    }
  }

  async function handleUpdate() {
    const result = await updatePost(id, {
      board_pk: board,
      category_pk: category,
      title,
      content: ref.current.getInstance().getMarkdown(),
    });
    if (result) {
      navigate(`/posts/${id}`);
    } else {
      alert("문제가 발생하여 포스트를 업데이트하지 못했습니다.");
    }
  }

  return (
    <div>
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div>
          <input
            style={{
              fontSize: "1.5rem",
              display: "block",
              width: "100%",
              fontWeight: "bold",
              outline: "none",
            }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}></input>
        </div>
        <div>
          Board
          <select value={board} onChange={(e) => setBoard(e.target.value)}>
            {boards.map((x) => (
              <option key={x.board_pk} value={x.board_pk}>
                {x.title}
              </option>
            ))}
          </select>
          {categories.length > 0 && (
            <>
              Category
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}>
                {categories.map((x) => (
                  <option key={x.category_pk} value={x.category_pk}>
                    {x.title}
                  </option>
                ))}
              </select>
            </>
          )}
          {id ? (
            <button onClick={handleUpdate}>Update</button>
          ) : (
            <button onClick={handlePost}>Post</button>
          )}
        </div>
        {version === 1 && (
          <div>이 포스트는 이전 버전의 홈페이지에서 작성되었습니다.</div>
        )}
        <div>
          {initialContent !== null && (
            <Editor
              initialValue={initialContent}
              previewStyle="vertical"
              height="600px"
              initialEditType="markdown"
              useCommandShortcut={true}
              usageStatistics={false}
              ref={ref}
            />
          )}
        </div>
      </div>
    </div>
  );
}
