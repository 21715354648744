import React, { useState } from "react";
import { login, logout, useUser } from "./utils/apis";
import "./Login.scss";
import classes from "utils/classes";

export function Login() {
  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [user] = useUser();

  async function handleLogin() {
    const res = await login(id, password);
    if (!res) alert("Login failed");
    else window.location.reload();
  }

  async function handleLogout() {
    await logout();
    window.location.reload();
  }

  const modalClasses = classes({ show: isVisible }, "modal");
  const backgroundClasses = classes({ show: isVisible }, "modal-background");

  let button = null;
  if (user) {
    button = <button onClick={handleLogout}>Logout</button>;
  } else {
    button = <button onClick={() => setIsVisible(true)}>Login</button>;
  }

  return (
    <div className="login">
      {button}
      <div
        className={backgroundClasses}
        onClick={() => setIsVisible(false)}></div>
      <div className={modalClasses}>
        <div className="inputs">
          <div className="row">
            <label>ID:</label>
            <input
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}></input>
          </div>
          <div className="row">
            <label>PW:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}></input>
          </div>
        </div>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}
