import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBoard } from "utils/apis";
import "./Board.scss";

export default function Board() {
  const { id } = useParams();
  const [board, error] = useBoard(id);
  const navigate = useNavigate();
  if (error) return <div>Error occurred while loading board</div>;
  if (!board) return <div>Board is loading...</div>;

  return (
    <div className="board-list">
      <div>
        <talbe>
          {board.map((post) => {
            const {
              post_pk: id,
              is_notice: isNotice,
              title,
              nickname,
              created_at: createdAt,
              category,
            } = post;
            return (
              <div
                className="row"
                style={{ fontWeight: isNotice ? "bold" : "" }}>
                <div className="category">{category || "일반"}</div>
                <div className="title">
                  <a href={`/posts/${id}`}>
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </a>
                </div>
                <div className="nickname">{nickname}</div>
                <div className="date">
                  {new Date(createdAt).toLocaleDateString()}
                </div>
              </div>
            );
          })}
        </talbe>
      </div>
      <div>
        <button onClick={() => navigate(`/edit?board=${id}`)}>New</button>
      </div>
    </div>
  );
}
