import { useEffect, useState } from "react";

async function get(url) {
  const res = await fetch(url);
  return await res.json();
}

async function post(url, body) {
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  });
  return await res.json();
}

async function del(url) {
  const res = await fetch(url, {
    method: 'delete'
  });
  return await res.json();
}

async function update(url, body) {
  const res = await fetch(url, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  });
  return await res.json();
}

function useFetch(url, enable = true) {
  const [result, setResult] = useState([null, null]);
  useEffect(() => {
    (async () => {
      if (!enable) return;
      try {
        const data = await get(url);
        setResult([data, null]);
      } catch (e) {
        setResult([null, e]);
      }
    })();
  }, [url, enable]);
  if (!enable) return result;
  return result;
}

export const usePage = (id) => useFetch(`/api/v1/pages/${id}`);
export const useMenus = () => useFetch("/api/v1/menus");
export const login = (id, pw) => post('/api/v1/auth/login', { id, pw });
export const logout = () => get('/api/v1/auth/logout');
export const useUser = () => useFetch('/api/v1/auth/user');

// Modules
export const usePost = (id) => useFetch(`/api/v1/modules/posts/${id}`, id);
export const addPost = (body) => post('/api/v1/modules/posts', body);
export const updatePost = (id, body) => update(`/api/v1/modules/posts/${id}`, body);

export const useBoard = (id) => useFetch(`/api/v1/modules/boards/${id}`);
export const useModule = (module) => useFetch(`/api/v1/modules/${module}`);
export const useHistory = () => useFetch('/api/v1/modules/history');



// Admin APIs
export const usePA = (hash) => useFetch(`/api/v1/admin/rbac/pas?hash=${hash}`);
export const addPA = (permission, rolePk) => post(`/api/v1/admin/rbac/pas`, { permission, rolePk });
export const deletePA = (pk) => del(`/api/v1/admin/rbac/pas/${pk}`);

export const useSA = (hash) => useFetch(`/api/v1/admin/rbac/sas?hash=${hash}`);
export const addSA = (subject, rolePk) => post(`/api/v1/admin/rbac/sas`, { subject, rolePk });
export const deleteSA = (pk) => del(`/api/v1/admin/rbac/sas/${pk}`);