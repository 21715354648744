import React, { useState } from "react";
import { addPA, addSA, deletePA, deleteSA, usePA, useSA } from "./utils/apis";
import "./Admin.scss";

function CheckBox({ v, onCheck, onUnCheck, refresh }) {
  async function handleChange(checked) {
    if (checked) {
      await onCheck();
    } else {
      await onUnCheck();
    }
    refresh();
  }

  const checked = v ? true : false;
  return (
    <input
      className="checkbox"
      type="checkbox"
      checked={checked}
      onChange={(e) => handleChange(e.target.checked)}></input>
  );
}

function Foldable({ title, children }) {
  const [isFolded, setIsFolded] = useState(true);

  return (
    <div>
      <div onClick={() => setIsFolded(!isFolded)} className="foldbutton">
        {isFolded ? "▶ Expand" : "▼ Collapse"}
      </div>
      <div
        style={{
          height: isFolded ? "0" : null,
          overflow: "hidden",
        }}>
        {children}
      </div>
    </div>
  );
}

function RbacPA() {
  const [hash, setHash] = useState("");
  const [data, error] = usePA(hash);
  if (error) return <div>Error occurred while loading PA.</div>;
  if (!data) return null;

  const { permissions: modules, roles, pas } = data;

  let paDict = {};
  pas.forEach((pa) => {
    paDict[`${pa.module}#${pa.action}#${pa.object}#${pa.rbac_role_pk}`] = pa;
  });

  function refresh() {
    setHash(Date.now().toString());
  }

  return (
    <div className="permissions">
      {Object.entries(modules).map(([moduleName, permissions]) => {
        return (
          <div>
            <h3>{moduleName} Permissions</h3>
            <Foldable>
              <table>
                <thead>
                  <tr>
                    <th>Permission</th>
                    {roles.map((role) => (
                      <th>{role.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permissions.map((p) => (
                    <tr>
                      <td>
                        {p.object
                          ? `${p.action} ${
                              p.object.name || p.object.id || p.object
                            }`
                          : p.action}
                      </td>
                      {roles.map((role) => {
                        const paKey = `${p.module}#${p.action}#${
                          p.object && (p.object.id || p.object)
                        }#${role.rbac_role_pk}`;
                        const pa = paDict[paKey];

                        return (
                          <td>
                            <CheckBox
                              v={pa}
                              onCheck={() => addPA(p, role.rbac_role_pk)}
                              onUnCheck={() => deletePA(pa.rbac_pa_pk)}
                              refresh={refresh}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Foldable>
          </div>
        );
      })}
    </div>
  );
}

function RbacSA() {
  const [hash, setHash] = useState("");
  const [data, error] = useSA(hash);

  if (error) return <div>Error occurred while loading SA</div>;
  if (!data) return null;

  const { users, roles, sas } = data;

  let saDict = {};
  sas.forEach((sa) => {
    saDict[sa.subject + "/" + sa.rbac_role_pk] = sa;
  });

  function refresh() {
    setHash(Date.now().toString());
  }

  return (
    <div className="permissions">
      <Foldable>
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>User Name</th>
              {roles.map((x) => (
                <th>{x.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr>
                <td>{user.id}</td>
                <td>{user.name}</td>
                {roles.map((role) => (
                  <td>
                    <CheckBox
                      v={saDict[user.user_pk + "/" + role.rbac_role_pk]}
                      onCheck={() => addSA(user.user_pk, role.rbac_role_pk)}
                      onUnCheck={() =>
                        deleteSA(
                          saDict[user.user_pk + "/" + role.rbac_role_pk]
                            .rbac_sa_pk
                        )
                      }
                      refresh={refresh}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Foldable>
    </div>
  );
}

export default function Admin() {
  return (
    <div className="admin">
      <div className="container">
        <h1>Admin page</h1>
        <h2>Permission Assignments</h2>
        <RbacPA />
        <h2>Subject Assignments</h2>
        <RbacSA />
      </div>
    </div>
  );
}
