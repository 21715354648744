import { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export default function useQuery(key) {
  const [searchParam, setSearchParam] = useSearchParams();
  const ref = useRef({});
  const { current } = ref;

  for (let [key, value] of searchParam.entries()) {
    current[key] = value;
  }

  const query = current[key] || '';
  const setQuery = useCallback((value) => setSearchParam({ ...current, [key]: value }), [key, current, setSearchParam]);

  return [query, setQuery];
};
